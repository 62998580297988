import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SermonItem from "../components/SermonItem";
import { constantActions } from "../store/constantSlice";
import EmptySermon from "../components/EmptySermon";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
//import "../assets/bootstrap/css/bootstrap.min.css";

export default function Sermons() {
  const dispatch = useDispatch();
  const companyName = useSelector((state) => state.constant.companyTitle);

  dispatch(constantActions.updatePageTitles(companyName + " . " + "Sermons"));
  dispatch(constantActions.setCurrentPage("Sermons"));

  const sermons = useSelector((state) => state.constant.data.sermons);

  /* pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 6;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = sermons.slice(firstIndex, lastIndex);
  const npage = Math.ceil(sermons.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function prevPage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
  }
  /* end pagination */

  return (
    <>
      <Helmet>
        <title>{companyName} . Sermons</title>
        <link rel="canonical" href="/sermons" />
        <meta name="author" content="www.jambumoses.com" />

        <meta
          name="keywords"
          content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
        />

        <meta
          name="description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />

        {/* opengraph */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={companyName + " | " + "Sermons"} />
        <meta
          property="og:description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        <meta property="og:url" content="https://golcitytv.com/sermons" />
        <meta property="og:site_name" content="golcitytv" />
        <meta
          property="article:modified_time"
          content="2023-03-02T10:27:44+00:00"
        />
        <meta
          property="og:image"
          content={require("../assets/imgs/posters/bible_logo.jpg")}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section>
        <div
          className="homeBanner store-banner"
          style={{
            height: "400px",
            background: `url(${require("../assets/imgs/posters/bible_logo.jpg")}) center no-repeat`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Link to="/donate" className="homeBanner-donate-btn">
            <button>
              <i className="fa fa-heart"></i> donate
            </button>
          </Link>
          <span className="homeBannerHeading">
            <h1></h1>
          </span>
        </div>

        <main className="home-main">
          <section className="home-main-context">
            {/* <span className="home-main-path">store</span> */}
            <div className="store-list">
              <a /* className="active" */>all</a> /<a>sermons</a>
            </div>

            {sermons.length > 0 && (
              <section className="store-products-container">
                {records.map(function (sermon) {
                  return <SermonItem key={sermon.id} sermon={sermon} />;
                })}
              </section>
            )}

            {sermons.length === 0 && <EmptySermon />}

            {sermons.length > 0 && (
              /* Pagination */
              <nav className="pagination-section">
                <ul className="pagination">
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      {" "}
                      <i
                        onClick={prevPage}
                        className="fa fa-arrow-left"
                      ></i>{" "}
                    </Link>
                  </li>

                  {numbers.map((n, i) => {
                    return (
                      <li
                        className={`page-item ${
                          currentPage === n ? "active" : ""
                        }`}
                        key={i}
                      >
                        <Link
                          to="#"
                          className="page-item"
                          onClick={() => changeCPage(n)}
                        >
                          {n}
                        </Link>
                      </li>
                    );
                  })}

                  <li>
                    <Link to="#" className="page-link">
                      {" "}
                      <i
                        onClick={nextPage}
                        className="fa fa-arrow-right"
                      ></i>{" "}
                    </Link>
                  </li>
                </ul>
              </nav>
            )}

            {/* end products container */}
          </section>
        </main>
      </section>

      <br />
    </>
  );
}
