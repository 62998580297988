import React from "react";
import { useDispatch, useSelector } from "react-redux";
/* components */
import CartContainer from "../components/CartContainer.jsx";
import EmptyCart from "../components/EmptyCart.jsx";
import CartSummaryBar from "../components/CartSummaryBar.jsx";
import { constantActions } from "../store/constantSlice.js";
import { Link } from "react-router-dom";
import RelatedSermons from "../components/RelatedSermons.jsx";
import { Helmet } from "react-helmet-async";

export default function Cart() {
  const dispatch = useDispatch();
  const companyName = useSelector((state) => state.constant.companyTitle);

  dispatch(constantActions.updatePageTitles(companyName + " . " + "Cart"));
  
  dispatch(constantActions.setCurrentPage("Cart"));

  const cart = useSelector((state) => state.constant.data.cart);

  return (
    <>
      <Helmet>
        <title>{companyName} . Cart</title>
        <link rel="canonical" href="/cart" />
        <meta name="author" content="www.jambumoses.com" />

        <meta
          name="keywords"
          content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
        />

        <meta
          name="description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        {/* opengraph */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={companyName + " | " + "Cart"} />
        <meta
          property="og:description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        <meta property="og:url" content="https://golcitytv.com/cart" />
        <meta property="og:site_name" content="golcitytv" />
        <meta
          property="article:modified_time"
          content="2023-03-02T10:27:44+00:00"
        />
        <meta property="og:image" content={""} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section>
        <main className="cart-section">
          <section className="cart-main">
            {/* checking if their cart items */}
            {cart.cartNo > 0 && (
              <CartContainer cartData={cart.products} cart={cart} />
            )}
            {cart.cartNo === 0 && <EmptyCart />}
          </section>

          {/* aside */}
          {cart.cartNo > 0 && <CartSummaryBar cartData={cart} />}
        </main>

        <RelatedSermons title="don't miss the message" data="" />

        <span className="home-main-path cart-path">
          store / products / mycart
        </span>

        <Link to="/sermons" className="cart-continue-shopping-button">
          continue shoping
        </Link>
      </section>

      <br />
    </>
  );
}
