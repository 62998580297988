import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { constantActions } from "../store/constantSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";

import slider from "react-slick/lib/slider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

export default function RelatedSermons({ title, data }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.constant.data.cart.products);
  const all_sermons = useSelector((state) => state.constant.data.sermons);
  //products.filter((value) => value.id !== action.payload.id);

  const settings = {
    dots: true,
    Infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  function addTocart(item) {
    if (cart.includes(item)) {
      alert("sermon already in your basket");
    } else {
      dispatch(constantActions.AddToCart(item));
      dispatch(constantActions.updateCartCount());
      /* refresh below */
      dispatch(constantActions.cartSubtotal());
      dispatch(constantActions.cartTotal());
    }
  }

  return (
    <>
      {all_sermons.length !== 0 && all_sermons.length >= 6 && (
        <section className="relatedSermon-section">
          <h2>{title}</h2>
          <section className="relatedSermon-container">
            <Slider {...settings}>
              {all_sermons.slice(0,10).map(function (item) {
                return (
                  <RelatedSermonItem
                    key={item.id}
                    cart={cart}
                    addTocart={addTocart}
                    data={item}
                  />
                );
              })}
            </Slider>
          </section>
        </section>
      )}
    </>
  );
}

export function RelatedSermonItem({ cart, addTocart, data }) {
  const navigate = useNavigate();
  /*   const title = "a prayer for the weak";
  const description =
    "This still features a beautiful landscape and the skies above it ...";
 */
  return (
    <>
      <div className="relatedSermon-item">
        <span
          onClick={() => addTocart(data)}
          className="relatedSermon-item-addCart"
          style={{
            backgroundColor: cart.includes(data) && "var(--cartButtonActive)",
          }}
        >
          {cart.includes(data) ? (
            <>
              <i
                className="fa fa-shopping-bag"
                style={{ paddingRight: "5px" }}
              ></i>{" "}
              cart
            </>
          ) : (
            "add cart"
          )}
        </span>

        <span className="relatedSermon-item-title">
          {data.title.slice(0, 21)} .
          <small>{data.description.slice(0, 65) + "..."}</small>
        </span>

        <span className="store-products-item-play_icon">
          <span
            className="play_circle"
            onClick={() => navigate("/sermons/details/" + data.id)}
          >
            <i style={{ paddingLeft: "5px" }} className="fa fa-play"></i>
          </span>
        </span>

        <LazyLoadImage src={data.poster} alt="" />
      </div>
    </>
  );
}
