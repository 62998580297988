import React from "react";
import { Link } from "react-router-dom";

export default function EmptyCart() {
  return (
    <>
      {/* empty cart */}
      <span className="cart-empty">
        <h1>empty cart</h1>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas
          vitae sed dignissimos doloremque praesentium debitis esse animi
          exercitationem quas
        </p>
        <Link to="/sermons">
          <i className="fa fa-shopping-cart"></i> shop now
        </Link>
      </span>
    </>
  );
}
