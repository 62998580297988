import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SocialPostItem from "./SocialPostItem";

export default function SocialPostScroll() {
  const dispatch = useDispatch();

  const socialPosts = useSelector((state) => state.constant.data.socialPosts);

  return (
    <>
      {socialPosts.length > 0 &&
        socialPosts[0].length === 4 &&
        socialPosts[1].length === 4 && (
          <section className="home-socials-post">
            <h6>
              <i className="fa fa-rss"></i> social posts
            </h6>
            <div className="home-socials-post-container">
              <section className="home-socials-post-scroller">
                {/* 200% animationed */}
                {socialPosts.map(function (container) {
                  return (
                    <>
                      <div className="home-socials-post-sec">
                        {container.map(function (item) {
                          return <SocialPostItem post={item} />;
                        })}
                      </div>
                    </>
                  );
                })}
              </section>
            </div>
          </section>
        )}
    </>
  );
}
