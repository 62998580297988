import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { constantActions } from "../store/constantSlice";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function SermonItem({ sermon }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.constant.data.cart.products);

  /* const id_test = useSelector((state) => state.constant.data.details.id);
  const sermon_test = useSelector(
    (state) => state.constant.data.details.sermon
  );
  const status_test = useSelector(
    (state) => state.constant.data.details.status
  ); */

  function addTocart(item) {
    if (cart.includes(item)) {
      alert("sermon already in your basket");
    } else {
      dispatch(constantActions.AddToCart(item));
      dispatch(constantActions.updateCartCount());
      /* refresh below */
      dispatch(constantActions.cartSubtotal());
      dispatch(constantActions.cartTotal());
    }
  }

  function showDetails(id, sermon = {}) {
    dispatch(constantActions.UpdateDetails({ type: "id", data: id }));
    dispatch(constantActions.UpdateDetails({ type: "sermon", data: sermon }));
    dispatch(constantActions.UpdateDetails({ type: "status", data: true }));

    // dispatch(constantActions.UpdateDetailsStatus());
    navigate("/sermons/details/" + id);
    /*     if (id_test !== "" && status_test === true && sermon_test.title !== "") {
      dispatch(constantActions.UpdateDetailsStatus());
      navigate("/sermons/details/" + id);
    } */
  }

  return (
    <>
      <div className="store-products-item">
        <span className="store-products-item-thumbnail">
          <span className="store-products-item-play_icon">
            <span
              className="play_circle"
              onClick={() => showDetails(sermon.id, sermon)}
            >
              <i style={{ paddingLeft: "5px" }} className="fa fa-play"></i>
            </span>
          </span>

          <LazyLoadImage src={sermon.poster} alt="" />
        </span>
        <span className="store-products-item-description">
          <span>{sermon.title} . </span>
          {sermon.description.length < 123 && sermon.description.length}
          {sermon.description.length > 123 &&
            sermon.description.slice(0, 123) + " ... "}
          {sermon.description.length > 123 && (
            <Link to={"/sermons/details/" + sermon.id}> read more</Link>
          )}
        </span>

        <span className="store-products-item-discount-price">
          {sermon.currency} {sermon.discount_price.toFixed(2)}
        </span>
        <span className="store-products-item-price">
          {sermon.currency} {sermon.price.toFixed(2)}
        </span>

        {sermon.license_type === "free" && (
          <Link
            to={sermon.video}
            download={sermon.title}
            target="_blank"
            /* ref={"noreferrer"} */
            className="store-products-item-button free "
          >
            <button type="button">download</button>
          </Link>
        )}

        {sermon.license_type === "purchase" && (
          <Link className="store-products-item-button purchase ">
            <button
              onClick={() => addTocart(sermon)}
              type="button"
              style={{
                backgroundColor:
                  cart.includes(sermon) && "var(--cartButtonActive)",
              }}
            >
              {cart.includes(sermon) ? (
                <>
                  <i className="fa fa-shopping-bag"></i> cart
                </>
              ) : (
                "add cart"
              )}
            </button>
          </Link>
        )}
      </div>
    </>
  );
}
