import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function GalleryTemp({ data }) {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card-columns">
            {data.map(function (item) {
              return <GalleryTempItem key={item.id} image={item} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

function GalleryTempItem({ image }) {
  return (
    <>
      <div className="card card-pin">
        <a href={image.url} data-lightbox="model" data-title={image.title}>
          <LazyLoadImage className="card-img" src={image.url} alt="" />
          <div className="overlay">
            <h2 className="card-title title">{image.title}</h2>
            {/* <div className="more"> */}
            {/* <i className="fa fa-eye" aria-hidden="true"></i> */}{" "}
            {/* More */}
            {/* </div> */}
          </div>
        </a>
      </div>
    </>
  );
}
