import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function OutNow() {
  const outNowData = useSelector((state) => state.constant.data.outNow_sermon);
  const data = new Date().getFullYear();

  return (
    <>
      {!(outNowData.id === undefined && outNowData.title === undefined) && (
        <div className="store-section">
          <div data-aos="fade-up" className="store-poster-section">
            <small>store</small>
            <span className="store-poster-links">
              golcity / {outNowData.type}
            </span>
            <span className="store-poster-thumbnail">
              <span>
                <LazyLoadImage src={outNowData.poster} alt="" />
              </span>
            </span>
          </div>
          {/* title */}
          <div className="store-poster-author-section">
            <span data-aos="fade-left" className="out">
              out
            </span>
            <span data-aos="fade-left" className="now">
              now
            </span>
            <span data-aos="fade-left" className="by">
              by {!outNowData.author.name ? "" : outNowData.author.name}
            </span>
            <Link
              data-aos="fade-left"
              style={{ display: "block" }}
              to={`/sermons/details/${outNowData.id}`}
            >
              check
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
