import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import RelatedSermons from "../components/RelatedSermons";
import { useDispatch, useSelector } from "react-redux";
import PreviewCheckoutSermons from "../components/PreviewCheckoutSermons";
import { Helmet } from "react-helmet-async";
import { constantActions } from "../store/constantSlice";

export default function Checkout() {
  const dispatch = useDispatch();
  const companyName = useSelector((state) => state.constant.companyTitle);

  dispatch(constantActions.updatePageTitles(companyName + " . " + "Checkout"));
  dispatch(constantActions.setCurrentPage("Checkout"));

  const cart = useSelector((state) => state.constant.data.cart);
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{companyName} . Checkout</title>
        <link rel="canonical" href="/checkout" />
        <meta name="author" content="www.jambumoses.com" />

        <meta
          name="keywords"
          content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
        />

        <meta
          name="description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        {/* opengraph */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={"404 Not Found"} />
        <meta
          property="og:description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        <meta property="og:url" content="https://golcitytv.com/checkout" />
        <meta property="og:site_name" content="golcitytv" />
        <meta
          property="article:modified_time"
          content="2023-03-02T10:27:44+00:00"
        />
        <meta property="og:image" content={""} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section className="checkout-section">
        <main>
          <form className="check-input-section" method="post" action="">
            <span className="check-input-item">
              <label htmlFor="">customer information</label>
              <span className="check-input-type-long">
                <input type="email" placeholder="Email Address *" required />
              </span>
            </span>

            <span className="check-input-item check-space">
              <label htmlFor="">Billing Details</label>
              <span className="check-input-type-long-two">
                <input type="text" placeholder="First Name *" required />
                <input type="text" placeholder="Last Name *" required />
              </span>
            </span>

            <span className="check-input-item ">
              <span
                className="check-input-type-long"
                style={{ marginTop: "13px" }}
              >
                <input type="email" placeholder="phone *" required />
              </span>
            </span>

            <span className="check-input-item" style={{ marginTop: "13px" }}>
              <span className="check-input-type-long-two">
                <select placeholder="Country / Region *" required>
                  <option value="">Country / Region *</option>
                  <option value="">uganda</option>
                </select>
              </span>
            </span>

            <span className="check-input-item" style={{ marginTop: "13px" }}>
              <span className="check-input-type-long-two">
                <select placeholder="Town / City *" required>
                  <option value="">Town / City *</option>
                  <option value="">uganda / kampala</option>
                </select>
                <select placeholder="PostCode / Zip *" required>
                  <option value="">PostCode / Zip *</option>
                </select>
              </span>
            </span>
          </form>

          <br />
          <br />
          <PreviewCheckoutSermons cart={cart} />
        </main>

        <aside>
          <h4>your order</h4>

          <CheckoutSummaryItem cartData={cart} />

          {cart.checkout.payment_methods.length > 0 && (
            <ShowPaymentMethods methods={cart.checkout.payment_methods} />
          )}

          <CheckoutSummaryButton
            cart={cart}
            info={cart.checkout}
            title={"make payments"}
          />
        </aside>
      </section>

      <RelatedSermons title="" data={[]} />
    </>
  );
}

function CheckoutSummaryItem({ cartData }) {
  return (
    <>
      <div className="cart-contain">
        <span className="cart-title-section">
          <span>quantity :</span>{" "}
          <small>
            {cartData.products.length} sermon
            {cartData.products.length > 1 && "s"}
          </small>
        </span>
        <span className="cart-title-section">
          <span>subtotal :</span>{" "}
          <small>
            {cartData.checkout.currency} {cartData.checkout.subtotal.toFixed(2)}
          </small>
        </span>
        <span className="cart-title-section">
          <span>tax :</span>{" "}
          <small>
            {cartData.checkout.currency} {cartData.checkout.tax.toFixed(2)}
          </small>
        </span>
        <span className="cart-title-section">
          <span>shipping fee :</span>{" "}
          <small>
            {cartData.checkout.currency}{" "}
            {cartData.checkout.shippingfee.toFixed(2)}
          </small>
        </span>
        <span className="cart-title-section">
          <span>total :</span>{" "}
          <small>
            {cartData.checkout.currency} {cartData.checkout.total.toFixed(2)}
          </small>
        </span>
      </div>
    </>
  );
}

function CheckoutSummaryButton({ cart, info, title }) {
  const navigate = useNavigate();
  const [valid, setValid] = useState(true);

  async function validPay(cart) {
    /* 
  valid info if not empty   
  no empty payment method
  no empty checkout form inputs
  */
    await fetch("http://localhost:5000/checkout/purchase", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ items: cart.products }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.url) {
          window.location.assign(response.url);
        }
      });
    //navigate("/make-payments");
  }

  return (
    <>
      <div className="check-pay-section">
        <span
          onClick={() => validPay(cart)}
          className={
            valid ? "check-make-payment-btn" : "check-make-payment-btn-disabled"
          }
        >
          {title}
        </span>
      </div>
    </>
  );
}

function ShowPaymentMethods({ methods }) {
  return <></>;
}
