import { createSlice } from "@reduxjs/toolkit";

const constantSlice = createSlice({
  name: "constant",
  initialState: {
    logo: { nav: "nav.png", browserTab: "" },
    companyTitle: "Golcity",
    currentPageTitle: "",
    currentPage: "",
    apiOrigin: "https://golcitybackend.onrender.com",

    data: {
      /* empty merchant */
      merchantDetails: {
        merchant_title: "",
        author: "",
        email: "",
        phone: {
          international: "",
          tel: "",
        },
        address: "",
        mapLocation:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2744.9509336809638!2d6.630494375580575!3d46.52887276177706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c2e3adea6563f%3A0x749f8667de6a4c87!2sRue%20de%20la%20Borde%2016B%2C%201018%20Lausanne%2C%20Switzerland!5e0!3m2!1sen!2sug!4v1698191134505!5m2!1sen!2sug",
        socials: [],
      },

      socialPosts: [],

      sermons: [],

      /* empty sermon */
      outNow_sermon: {},

      gallery: [],

      details: {
        id: "",
        status: true,
        sermon: {
          id: "",
          updated_at: "",
          date: "",
          time: "",
          size: "",
          title: "",
          video: "",
          type: "",
          author: { name: "", url: "" },
          img_url: "",
          poster: "",
          license_type: "",
          currency: "",
          price: 0,
          discount_price: 0,
          quantity: 0,
          subtotal: 0,
          rate: 0,
          description: "",
          paragraph: [
            {
              id: 1,
              verse: "",
              p: "",
            },

            {
              id: 2,
              verse: "",
              p: "",
            },
            {
              id: 3,
              verse: "",
              p: "",
            },

            {
              id: 1,
              verse: "",
              p: "",
            },
          ],

          rating: [
            {
              id: 1,
              url: "",
              title: "",
              comment: "",
              rating: 5,
            },
            {
              id: 2,
              url: "",
              title: "",
              comment: "",
              rating: 5,
            },
            {
              id: 3,
              url: "",
              title: "",
              comment: "",
              rating: 5,
            },
          ],

          trailers: [
            { id: 1, url: "" },
            {
              id: 2,
              url: "",
            },
            {
              id: 3,
              url: "",
            },
          ],
        },
      },

      cart: {
        cartNo: 0,
        products: [],

        checkout: {
          currency: "shs",
          subtotal: 0,
          tax: 0,
          shippingfee: 0,
          total: 0,
          customer: {
            _id: "" /* user_id */,
            email: "",
            firstName: "",
            lastName: "",
            address: "",
            country: "",
            city: "",
            contact: "",
            payment_method: "",
          },
          payment_methods: [],
        },
      },

      donation: {
        type: "one-time" /* one-time or monthly */,
        currency: "usd",
        price: 0,
        honors: false,
        someonesHonors: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        },
        personalInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        },
        billingInfo: {
          address: "",
          addressLine: "",
          city: "",
          state: "",
          postalCode: "",
          country: "",
        },
        /* for auto monthly payments */
        cardInfo: {
          cardName: "",
          cardNumber: "",
          month: "",
          year: "",
          cvv: "",
        },
      },
    },
  },

  reducers: {
    donateUpdate(state, action) {
      if (action.payload.tag === "type") {
        state.data.donation.type = action.payload.data;
      }
      if (action.payload.tag === "currency") {
        state.data.donation.currency = action.payload.data;
      }
      if (action.payload.tag === "price") {
        if (state.data.donation.type === "monthly") {
          state.data.donation.price =
            action.payload.data + " " + state.data.donation.type;
        }
        if (state.data.donation.type === "one-time") {
          state.data.donation.price = action.payload.data;
        }
      }
      if (action.payload.tag === "honors") {
        state.data.donation.honors = !state.data.donation.honors;
      }
      /* honor */
      if (action.payload.tag === "honor-fname") {
        state.data.donation.someonesHonors.firstName = action.payload.data;
      }
      if (action.payload.tag === "honor-lname") {
        state.data.donation.someonesHonors.lastName = action.payload.data;
      }
      if (action.payload.tag === "honor-email") {
        state.data.donation.someonesHonors.email = action.payload.data;
      }
      if (action.payload.tag === "honor-phoneNumber") {
        state.data.donation.someonesHonors.phoneNumber = action.payload.data;
      }
      /* personal */
      if (action.payload.tag === "personal-info-firstname") {
        state.data.donation.personalInfo.firstName = action.payload.data;
      }
      if (action.payload.tag === "personal-info-lastname") {
        state.data.donation.personalInfo.lastName = action.payload.data;
      }
      if (action.payload.tag === "personal-info-email") {
        state.data.donation.personalInfo.email = action.payload.data;
      }
      if (action.payload.tag === "personal-info-phoneNumber") {
        state.data.donation.personalInfo.phoneNumber = action.payload.data;
      }
      /* billing */
      if (action.payload.tag === "billing-info-address") {
        state.data.donation.billingInfo.address = action.payload.data;
      }
      if (action.payload.tag === "billing-info-address-line") {
        state.data.donation.billingInfo.addressLine = action.payload.data;
      }
      if (action.payload.tag === "billing-info-city") {
        state.data.donation.billingInfo.city = action.payload.data;
      }
      if (action.payload.tag === "billing-info-state") {
        state.data.donation.billingInfo.state = action.payload.data;
      }
      if (action.payload.tag === "billing-info-postalCode") {
        state.data.donation.billingInfo.postalCode = action.payload.data;
      }
      if (action.payload.tag === "billing-info-country") {
        state.data.donation.billingInfo.country = action.payload.data;
      }
    },

    updateConstants(state, action) {
      state.data = action.payload;
    },

    updatePageTitles(state, action) {
      state.currentPageTitle = action.payload;
    },

    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },

    // update products store
    UpdateGolcitytv(state, action) {
      //sermons
      if (action.payload.type === "sermons") {
        state.data.sermons = action.payload.data;
      }

      // gallery
      if (action.payload.type === "gallery") {
        state.data.gallery = action.payload.data;
      }

      // merchant
      if (action.payload.type === "merchant") {
        state.data.merchantDetails = action.payload.data;
      }

      // outnow
      if (action.payload.type === "outnow") {
        state.data.outNow_sermon = action.payload.data;
      }
      // socials
      if (action.payload.type === "socials") {
        state.data.socialPosts = action.payload.data;
      }

      // outnow
      if (action.payload.type === "cart") {
        state.data.cart = action.payload.data;
      }
    },

    /* cart */
    updateCartCount(state) {
      state.data.cart.cartNo = state.data.cart.products.length;
    },

    AddToCart(state, action) {
      state.data.cart.products.push(action.payload);
    },

    AddItemToCart(state, action) {
      state.data.cart.products.map(function (item) {
        if (item.id === action.payload.id) {
          item.quantity++;
          item.subtotal = item.quantity * item.price;
        }
        // TODO _ remove _ null
        return null;
      });
    },

    ReduceItemFromCart(state, action) {
      if (action.payload.quantity === 1) {
        //remove
        state.data.cart.products = state.data.cart.products.filter(
          (value) => value.id !== action.payload.id
        );
        /* end */
      } else {
        state.data.cart.products.map(function (item) {
          if (item.id === action.payload.id) {
            if (item.quantity !== 1) {
              item.quantity--;
              item.subtotal = item.quantity * item.price;
            }
          }
          // TODO _ remove _ null
          return null;
        });
        /* end of if */
      }
    },

    RemoveFromCart(state, action) {
      state.data.cart.products = state.data.cart.products.filter(
        (value) => value.id !== action.payload.id
      );
    },

    cartSubtotal(state) {
      let newarray = [];

      state.data.cart.products.map(function (item) {
        newarray.push(item.subtotal);
        // TODO _ remove _ null
        return null;
      });

      state.data.cart.checkout.subtotal = newarray.reduce((total, current) => {
        return total + current;
      }, 0);
    },

    cartTotal(state) {
      state.data.cart.checkout.total =
        state.data.cart.checkout.shippingfee +
        state.data.cart.checkout.subtotal +
        state.data.cart.checkout.tax;
    },

    UpdateDetails(state, action) {
      if (action.payload.type === "id") {
        state.data.details.id = action.payload.data;
        //constantActions.UpdateDetailsStatus()
      }

      if (action.payload.type === "sermon") {
        state.data.details.sermon = action.payload.data;
      }

      if (action.payload.type === "status") {
        state.data.details.status = action.payload.data;
      }
    },

    getDetails(state, action) {
      state.data.sermons.map(function (item) {
        if (item.id === action.payload) {
          state.data.details.status = true;
          state.data.details.sermon = item;
        } else {
          //state.data.details.id = "";
          //state.data.details.status = false;
          //state.data.details.sermon = {};
        }
      });
    },

    UpdateDetailsStatus(state) {
      if (state.data.details.id === "") {
        state.data.details.status = false;
      } else {
        state.data.details.status = true;

        state.data.sermons.map(function (item) {
          if (item.id === state.data.details.id) {
            state.data.details.status = true;
            state.data.details.sermon = item;
          } else {
            state.data.details.id = "";
            state.data.details.status = false;
            //state.data.details.sermon = {};
          }
        });
      }
    },
  },
});

export const constantActions = constantSlice.actions;
export default constantSlice;
