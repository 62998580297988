import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";

export default function CarouselComponent() {
  return (
    <>
      <section className="homeBanner" style={{ height: "400px" }}>
        <Link to="/donate" className="homeBanner-donate-btn">
          <button >
            <i className="fa fa-heart"></i> donate
          </button>
        </Link>

        {/* carousel */}
        <section className="carousel-section">
          <LazyLoadImage src={require("../assets/imgs/posters/frf.jpg")} alt="" />
{/*           <Carousel
            prevIcon={
              <span
                aria-hidden="true"
                className="carousel-control-prev-icon"
                style={{ fontSize: "10px" }}
              />
            }
            nextIcon={
              <span aria-hidden="true" className="carousel-control-next-icon" />
            }
            interval={5000}
            indicators={false}
            pause={false}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
              padding: "0px",
            }}
          >
            <Carousel.Item className="carousel-item-container">
              <div
                className="carousel-contain"
              >
                <LazyLoadImage 
                  src={require("../assets/imgs/posters/frf.jpg")}
                  alt=""
                />
              </div>
            </Carousel.Item>
          </Carousel> */}
        </section>
      </section>
    </>
  );
}
