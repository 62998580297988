import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GalleryImage from "../components/GalleryImage";
import EmptyGallery from "../components/EmptyGallery";
import { constantActions } from "../store/constantSlice";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import GalleryTemp from "../templating/GalleryTemp";

export default function Gallery() {
  const dispatch = useDispatch();
  const companyName = useSelector((state) => state.constant.companyTitle);

  dispatch(constantActions.updatePageTitles(companyName + " . " + "Gallery"));
  dispatch(constantActions.setCurrentPage("Gallery"));

  const gallery = useSelector((state) => state.constant.data.gallery);

  /* pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 20;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = gallery.slice(firstIndex, lastIndex);

  const npage = Math.ceil(gallery.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function prevPage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
  }
  /* end pagination */

  return (
    <>
      <Helmet>
        <title>{companyName} . Gallery</title>
        <link rel="canonical" href="/gallery" />
        <meta name="author" content="www.jambumoses.com" />

        <meta
          name="keywords"
          content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
        />

        <meta
          name="description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
                {/* opengraph */}
                <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={companyName + " | " + "Gallery"} />
        <meta
          property="og:description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        <meta property="og:url" content="https://golcitytv.com/gallery" />
        <meta property="og:site_name" content="golcitytv" />
        <meta
          property="article:modified_time"
          content="2023-03-02T10:27:44+00:00"
        />
        <meta
          property="og:image"
          content={""}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section className="gallery-section">
        <h2>explore golcitytv</h2>
        <hr />

        {gallery.length === 0 && <EmptyGallery />}

        {gallery.length > 0 && (
          <section className="gallery-container">
              <GalleryTemp data={records}/> {/* <GalleryImage  /> */}
          </section>
        )}


        {gallery.length > 0 && (
          /* Pagination */
          <nav className="pagination-section">
            <ul className="pagination">
              <li className="page-item">
                <Link to="#" className="page-link">
                  {" "}
                  <i onClick={prevPage} className="fa fa-arrow-left"></i>{" "}
                </Link>
              </li>

              {numbers.map((n, i) => {
                return (
                  <li
                    className={`page-item ${currentPage === n ? "active" : ""}`}
                    key={i}
                  >
                    <Link
                      to="#"
                      className="page-item"
                      onClick={() => changeCPage(n)}
                    >
                      {n}
                    </Link>
                  </li>
                );
              })}

              <li>
                <Link to="#" className="page-link">
                  {" "}
                  <i onClick={nextPage} className="fa fa-arrow-right"></i>{" "}
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </section>
    </>
  );
}
