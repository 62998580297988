import React from "react";
import { useDispatch } from "react-redux";
import { constantActions } from "../store/constantSlice";
import { Link } from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";

export default function CartItem({ cart }) {
  const dispatch = useDispatch();

  function add(item) {
    dispatch(constantActions.AddItemToCart(item));
    dispatch(constantActions.updateCartCount()); //update cartCount
    /* refresh below */
    dispatch(constantActions.cartSubtotal());
    dispatch(constantActions.cartTotal());
  }

  function minus(item) {
    dispatch(constantActions.ReduceItemFromCart(item));
    dispatch(constantActions.updateCartCount()); //update cartCount
    /* refresh below */
    dispatch(constantActions.cartSubtotal());
    dispatch(constantActions.cartTotal());
  }

  return (
    <>
      <div className="cart-item">
        {" "}
        {/* cart item */}
        <span className="cart-item-thumbnail">
          <LazyLoadImage src={cart.poster} alt="" />
        </span>
        <span className="cart-item-details">
          <p>
            <span>{cart.title} . </span>
            {cart.description.slice(0, 280)}...
            <Link
              to={"/sermons/details/" + cart.id}
              style={{
                fontSize: "10px",
                textTransform: "capitalize",
                color: "var(--link)",
              }}
            >
              {" "}
              details
            </Link>{" "}
          </p>

          {/*           <div className="cart-item-details-quantity-section">
            <span onClick={() => minus(cart)}>
              <i style={{ cursor: "pointer" }} className="fa fa-minus"></i>
            </span>
            <span>{cart.quantity}</span>
            <span onClick={() => add(cart)}>
              <i style={{ cursor: "pointer" }} className="fa fa-add"></i>
            </span>
          </div> */}

          <div className="cart-item-details-price-section">
            <span className="cart-item-details-price-discount">
              {cart.currency} {cart.discount_price.toFixed(2)}
            </span>
            <span>
              {cart.currency} {cart.subtotal.toFixed(2)}
            </span>
          </div>
        </span>
      </div>
      {/* end of cart item */}
      <script></script>
    </>
  );
}
