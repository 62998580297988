import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { constantActions } from "../store/constantSlice";
import RelatedSermons from "../components/RelatedSermons";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Details() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const id_test = useSelector((state) => state.constant.data.details.id);
  const status = useSelector((state) => state.constant.data.details.status);
  const sermon = useSelector((state) => state.constant.data.details.sermon);
  const cart = useSelector((state) => state.constant.data.cart.products);
  const topSermons = useSelector((state) => state.constant.data.sermons);
  //  sermon.filter((value) => value.id !== action.payload.id

  const companyName = useSelector((state) => state.constant.companyTitle);
  dispatch(constantActions.updatePageTitles(companyName + " . " + "Details"));
  dispatch(constantActions.setCurrentPage("Details"));

  const params = useParams();
  const url_id = params.id;
  if (url_id) {
    dispatch(constantActions.UpdateDetails({ type: "id", data: url_id }));
    //dispatch(constantActions.UpdateDetailsStatus());
  }

  function addToCart(item) {
    if (cart.includes(item)) {
      alert("sermon already in your basket");
    } else {
      dispatch(constantActions.AddToCart(item));
      dispatch(constantActions.updateCartCount());
      /* refresh below */
      dispatch(constantActions.cartSubtotal());
      dispatch(constantActions.cartTotal());
      //dispatch(constantActions.UpdateDetailsStatus());
    }
  }

  function showDetail(id, sermon = {}) {
    /* 
    dispatch(constantActions.UpdateDetails({ type: "id", data: id }));
    dispatch(constantActions.UpdateDetails({ type: "sermon", data: sermon }));
    dispatch(constantActions.UpdateDetails({ type: "status", data: true })); */
    //navigate("/sermons/details/" + id);
  }

  /*   useEffect(() => {
    dispatch(constantActions.UpdateDetails({ type: "id", data: url_id }));
    dispatch(constantActions.UpdateDetailsStatus());
  }, []); */

  const update_Interval = setInterval(() => {
    if (id_test === url_id) {
      dispatch(constantActions.UpdateDetails({ type: "id", data: url_id }));
      dispatch(constantActions.getDetails(url_id));
    } else {
      clearTimeout(update_Interval);
    }
  }, 1000);

  function updateRoute(current_id) {
    dispatch(constantActions.UpdateDetails({ type: "id", data: current_id }));
    dispatch(constantActions.getDetails(current_id));

    //dispatch(constantActions.UpdateDetails({ type: "sermon", data: sermon }));
    dispatch(constantActions.UpdateDetails({ type: "status", data: true }));
    //const update_Interval = setInterval(() => {
    //if (id_test !== current_id) {
    //dispatch(constantActions.UpdateDetails({ type: "id", data: current_id }));
    //dispatch(constantActions.getDetails(current_id));
    //} else {
    //clearTimeout(update_Interval);
    //}
    //}, 1000);
  }

  return (
    <>
      {status && (
        <Helmet>
          <title>
            {companyName} | {sermon.title}
          </title>

          <link rel="canonical" href="/details" />
          <meta name="author" content="www.jambumoses.com" />
          <meta
            name="keywords"
            content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
          />

          <meta name="description" content={sermon.description} />

          {/* opengraph */}
          <meta property="og:locale" content="en_GB" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={companyName + " | " + sermon.title}
          />
          <meta property="og:description" content={sermon.description} />
          <meta
            property="og:url"
            content={"https://golcitytv.com/sermons/details/" + sermon.id}
          />
          <meta property="og:site_name" content="golcitytv" />
          <meta
            property="article:modified_time"
            content="2023-03-02T10:27:44+00:00"
          />
          <meta property="og:image" content={sermon.poster} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      )}

      <section className="details-section">
        {status && (
          <>
            <main>
              <div className="video-container">
                <video
                  src={sermon.video}
                  poster={sermon.poster}
                  autoPlay={false}
                  loop={false}
                  controls
                ></video>
              </div>
              <section className="video-info-container">
                <div className="video-info-author">
                  <span className="thumbnail">
                    <LazyLoadImage src={sermon.author.url} alt="" />
                  </span>
                  <span className="context">
                    <span>{sermon.author.name}</span>
                    <small>{sermon.updated_at}</small>
                  </span>
                </div>
                <h1>{sermon.title}</h1>
                <p>{sermon.description}</p>

                <span className="video-info-price-section">
                  {sermon.currency} {sermon.price.toFixed(2)}
                </span>

                <div className="video-info-buttons">
                  {sermon.license_type === "purchase" &&
                  
                  <button
                    onClick={() => addToCart(sermon)}
                    type="button"
                    className="btn-cart"
                    style={{
                      backgroundColor:
                        cart.includes(sermon) && "var(--cartButtonActive)",
                    }}
                  >
                    {cart.includes(sermon) ? (
                      <>
                        <i className="fa fa-shopping-bag"></i> cart
                      </>
                    ) : (
                      "add cart"
                    )}
                  </button>
                  }
                </div>
              </section>
            </main>

            <TopSermonsComponent
              detail_id={url_id}
              cart={cart}
              updateRoute={updateRoute}
              showDetail={showDetail}
              data={topSermons}
            />
          </>
        )}

        {!status && <Navigate to={"/sermons"} />}
      </section>

      {status && <RelatedSermons title="don't miss the message" data="" />}
    </>
  );
}

/* top sermons component */
function TopSermonsComponent({
  detail_id,
  cart,
  updateRoute,
  showDetail,
  data,
}) {
  const record_noDetail = data.filter((value) => value.id !== detail_id);

  const records = [];

  record_noDetail.map(function (item) {
    if (cart.includes(item)) {
    } else {
      records.push(item);
    }
  });

  return (
    <aside>
      <h1>top sermons</h1>
      <section className="details-aside-top-sermon-section">
        {records.map(function (item) {
          return (
            <TopSermonItem
              key={item.id}
              updateRoute={updateRoute}
              showDetail={showDetail}
              data={item}
            />
          );
        })}
      </section>
    </aside>
  );
}

function TopSermonItem({ updateRoute, showDetail, data }) {
  return (
    <div className="details-aside-top-sermon-item">
      <div className="details-aside-top-sermon-item-thumbnail">
        <span className="play-icon">
          <Link
            to={"/sermons/details/" + data.id}
            onClick={() => updateRoute(data.id)}
            className="play-circle"
          >
            {/* onClick={()=> showDetail(data.id, data) } */}
            <i style={{ paddingLeft: "3px" }} className="fa fa-play"></i>
          </Link>
        </span>
        <LazyLoadImage src={data.poster} alt="" />
      </div>
      {/* context */}
      <div className="details-aside-top-sermon-item-context">
        <h3 style={{ textTransform: "capitalize" }}>
          {data.title.slice(0, 28)}
        </h3>
        <p>{data.description.slice(0, 78)} ...</p>
      </div>
    </div>
  );
}
