import React from "react";
import CartItem from "./CartItem";
import { Link } from "react-router-dom";

export default function CartContainer({ cartData,cart }) {
  return (
    <>
      <div className="cart-main-container">
        {/* cart with items */}
        <h1>my cart</h1>
        <div className="cart-item-container">
          {cartData.map(function (cart) {
            return <CartItem cart={cart} />;
          })}
        </div>
        {/* checkout rounting */}
        {cartData.length > 0 && (
          <section className="cart-bottom-checkout-section">
            <div className="cart-bottom-checkout-left">
              <h4>
                subTotal:<span>{cart.checkout.currency} {cart.checkout.subtotal.toFixed(2)}</span>
              </h4>
            </div>
            <div className="cart-bottom-checkout-right">
              <Link to="/checkout">
                checkout <i className="fa fa-angle-right"></i>
              </Link>
            </div>
          </section>
        )}
      </div>
    </>
  );
}
