import React from "react";
import { constantActions } from "../store/constantSlice";
import { useDispatch, useSelector } from "react-redux";
import RelatedSermons from "../components/RelatedSermons";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import LiveSreen from "../components/LiveSreen";

export default function Live() {
  const dispatch = useDispatch();
  const companyName = useSelector((state) => state.constant.companyTitle);

  dispatch(constantActions.updatePageTitles(companyName + " . " + "Live"));
  dispatch(constantActions.setCurrentPage("Live"));

  return (
    <>
      <Helmet>
        <title>{companyName} . Live</title>
        <link rel="canonical" href="/live" />
        <meta name="author" content="www.jambumoses.com" />

        <meta
          name="keywords"
          content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
        />

        <meta
          name="description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />

        {/* opengraph */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={companyName + " | " + "Live"} />
        <meta
          property="og:description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        <meta property="og:url" content="https://golcitytv.com/live" />
        <meta property="og:site_name" content="golcitytv" />
        <meta
          property="article:modified_time"
          content="2023-03-02T10:27:44+00:00"
        />
        <meta
          property="og:image"
          content={require("../assets/imgs/skins/jason-leung-IhW9k4f18kI-unsplash.jpg")}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section>
{/*         <div
          className="homeBanner"
          style={{
            height: "400px",
            background: `url(${require("../assets/imgs/skins/jason-leung-IhW9k4f18kI-unsplash.jpg")}) center no-repeat`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Link to="/donate" className="homeBanner-donate-btn">
            <button>
              <i className="fa fa-heart"></i> donate
            </button>
          </Link>
          <span className="homeBannerHeading">
            
            <a className="banner-watch-now" target="_blank" href="https://www.youtube.com/@golcitytv">
              <button>
                <i className="fa fa-rss"></i> watch now
              </button>
            </a>
          </span>
        </div> */}

        <LiveSreen/>

        <main className="home-main live-section">
          <p className="live-p">
            It’s time gather together wherever you live, with other people, in
            person, to watch weekly services, connect with God through music,
            and conversations. We strive to create an inviting place, one that’s
            extremely approachable no matter what you believe, where you come
            from, or who you know
          </p>
        </main>
      </section>

      <RelatedSermons title="don't miss the message" data="" />
      <br />
    </>
  );
}
