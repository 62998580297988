import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function EmailSubscription() {
  const [emailList, setEmailList] = useState("");

  async function subcribeToEmailList(data) {
    await fetch("http://localhost:5000/subcribe", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email: data }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.url) {
          window.location.assign(response.url);
        }
      });
  }

  return (
    <>
      <section className="email-subscription">
        <span>
          <h1>receive newsletters</h1>
          <p>
            Subscribe to our newsletters and stay updated, with{" "}
            <span>Pastor Elijah Mugwanya</span>
            <br />
            Glory Of The Lord's City Ministries International
          </p>
          <form>
            <input
              className="subscribe-input"
              name="subscribe"
              type="email"
              placeholder="Enter Your Email Address Here"
              onChange={(e) => setEmailList(e.target.value)}
              value={emailList}
            />
            <button
              type="button"
              onClick={() => subcribeToEmailList(emailList)}
            >
              subcribe
            </button>
          </form>
        </span>

        <span className="email-subscription-image">
          <LazyLoadImage
            src={require("../assets/imgs/posters/messages.png")}
            alt=""
          />
        </span>
      </section>
    </>
  );
}
