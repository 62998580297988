import React from 'react'

export default function CartSummaryBar({cartData}) {
  return (
    <>
<aside className="cart-aside">
    <h2>order summary</h2>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, consequuntur aliquam ipsam cupiditate, incidunt asperiores </p>
    <div className="cart-contain">
        <span className="cart-title-section"><span>quantity :</span> <small>{cartData.products.length} sermon{(cartData.products.length > 1)&& "s"}</small></span>
        <span className="cart-title-section"><span>subtotal :</span> <small>{cartData.checkout.currency} {cartData.checkout.subtotal.toFixed(2)}</small></span>
        <span className="cart-title-section"><span>tax :</span> <small>{cartData.checkout.currency} {cartData.checkout.tax.toFixed(2)}</small></span>
        <span className="cart-title-section"><span>shipping fee :</span> <small>{cartData.checkout.currency} {cartData.checkout.shippingfee.toFixed(2)}</small></span>
        <span className="cart-title-section"><span>total :</span> <small>{cartData.checkout.currency} {cartData.checkout.total.toFixed(2)}</small></span>
    </div>
{/*     <a href="" className="cart-summary-btn"><button type="button">proceed checkout</button></a>

    {/* checkout *
    <h3>customer information</h3>
    <div className="cart-contain">
        <span className="cart-title-section"><span>Email :</span> <small>jambcareer@gmail.com</small></span>
        <span className="cart-title-section"><span>Tel :</span> <small>+256-76-132-7212</small></span>
        <span className="cart-title-section"><span>Country :</span> <small>uganda</small></span>
        <span className="cart-title-section"><span>zip-code :</span> <small>123454</small></span>
        <span className="cart-title-section"><span>City :</span> <small>kampala</small></span>
        <span className="cart-title-section"><span>Address :</span> <small>muyenga</small></span>
        <span className="cart-title-section"><span>{/* empty span *</span> <small className="edit">edit</small></span>
    </div>
    <a href="" className="cart-checkout-btn"><button type="button">checkout</button></a>
 */}
</aside>

    </>
  )
}
