import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import { Link } from 'react-router-dom';
import $ from "jquery";
import {LazyLoadImage} from "react-lazy-load-image-component";

export default function Header() {
  const dispatch = useDispatch();

    const logo = "";
    const cart = useSelector((state) => state.constant.data.cart.cartNo);
    const current_page = useSelector((state) => state.constant.currentPage);

    function MobileNav(){
      $("#mobile-nav").slideToggle("slow").css({display:"flex"})
    }


  return (
<>
<header>

    <section className="header-container">
        <span className="header-logo"><LazyLoadImage src={require("../assets/imgs/church/Logo-removebg-preview.png")} alt=""/></span>
        <nav>
            <span><Link className={(current_page === "Home") ? "active": null } to="/">Home</Link></span>
            <span><Link className={(current_page === "About") ? "active": null } to="/about">About</Link></span>
            <span><Link className={(current_page === "Live") ? "active": null } to="/live">Live</Link></span>
            <span><Link className={(current_page === "Sermons") ? "active": null } to="/sermons">Sermons</Link></span>
            <span><Link className={(current_page === "Gallery") ? "active": null } to="/gallery">Gallery</Link></span>
            <span><Link className={(current_page === "Contact") ? "active": null } to="/contact">Contact</Link></span>
        </nav>

        {/* <div>
        <span
          ><input
            type="search"
            name="search"
            id=""
            placeholder="search product"
        /></span>
        <span><i className="fa fa-search"></i></span>
      </div> */}

        <span className="header-cart">
          <Link to="/cart">
            <i style={{paddingRight: 5, fontSize: "15px", color: "var(--cartIcon)"}} className="fa fa-shopping-bag"></i> 
             {cart}
          </Link>
        </span>

        <span className='header-mobile-icon' >
          <Link to="/cart">
          <i style={{paddingRight: 5, fontSize: "15px", color: "var(--cartIcon)"}} className="fa fa-shopping-bag"></i> 
             {cart}
          </Link>

          <span onClick={MobileNav} style={{cursor: "pointer"}}>
          <i  style={{paddingRight: 0, fontSize: "15px", color: "var(--cartIcon)"}} className="fa fa-bars"></i>
          </span>
        </span>
        {/* <span>create account</span> */}
    </section>

    
        {/* mobile nav */}
        <section className='header-mobile-nav' >
          <div id='mobile-nav'>
            <span><Link onClick={MobileNav} className={(current_page === "Home") ? "active": null } to="/">Home</Link></span>
            <span><Link onClick={MobileNav} className={(current_page === "About") ? "active": null } to="/about">About</Link></span>
            <span><Link onClick={MobileNav} className={(current_page === "Live") ? "active": null } to="/live">Live</Link></span>
            <span><Link onClick={MobileNav} className={(current_page === "Sermons") ? "active": null } to="/sermons">Sermons</Link></span>
            <span><Link onClick={MobileNav} className={(current_page === "Gallery") ? "active": null } to="/gallery">Gallery</Link></span>
            <span><Link onClick={MobileNav} className={(current_page === "Contact") ? "active": null } to="/contact">Contact</Link></span>
          </div>
        </section>
</header>

    </>
  )
}
