import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

export default function SocialPostItem({ post }) {
  const navigate = useNavigate();

  return (
    <>
      <a target="_blank" href={post.link} style={{textDecoration:"none",color:"var(--color71)"}} className="home-socials-post-sec-item">
        {/* post */}
        <span className="home-socials-post-sec-item-post-type">
          <i
            style={{ fontSize: "14px", color: "white" }}
            className={
              post.from === "twitter"
                ? "fab fa-twitter"
                : post.from === "instagram"
                ? "fab fa-instagram"
                : post.from === "facebook"
                ? "fab fa-facebook"
                : null
            }
          ></i>
        </span>
        <span className="home-socials-post-sec-item-thumbnail">
          <LazyLoadImage
            src={post.url}
            alt=""
          />
        </span>
        <span className="home-socials-post-sec-item-description">
          {post.message}
        </span>
        <span className="home-socials-post-sec-item-post-timestamp">
          posted: today-12:00 am
        </span>
      </a>
    </>
  );
}
