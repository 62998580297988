import React from "react";
import { useDispatch } from "react-redux";
import { constantActions } from "../store/constantSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function PreviewCheckoutSermons({ cart }) {
  const dispatch = useDispatch();

  function deleteFromCart(item) {
    dispatch(constantActions.RemoveFromCart(item));
    dispatch(constantActions.updateCartCount()); //update cartCount
    /* refresh below */
    dispatch(constantActions.cartSubtotal());
    dispatch(constantActions.cartTotal());
  }

  return (
    <>
      <hr />
      <section className="check-orders-section">
        {cart.products.map(function (item) {
          return (
            <>
              <div className="check-order-item">
                <span
                  onClick={() => deleteFromCart(item)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-trash"></i>
                </span>
                <LazyLoadImage src={item.poster} alt="" />
                <small>
                  {item.currency} {item.subtotal}
                </small>
              </div>
            </>
          );
        })}
      </section>
    </>
  );
}
