import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function Footer() {

  const merchant = useSelector(state=>state.constant.data.merchantDetails)

  const iconStyle ={
    fontSize: "13px",
    marginRight: "5px"
  }

  return (
<>
<footer className="row" >
    <section className="col-12">

      <div className="footer-top-bar">
        <span className="footer-top-bar-select-country">
          {/* <span>country</span>
          <select name="countries" id="">
            <option value="1">uganda</option>
          </select> */}
        </span>

        <h3>elijah mugwanya</h3>

        <span className="footer-top-bar-donate-btn">
          <Link to="/donate">
            <button type="button">donate</button>
          </Link>
        </span>
      </div>

      <div className="footer-menu">
        
        <div className="footer-menu-item">
          <h3>quick links</h3>
          <ul>
            <li><Link to="/"> <i className="fa fa-home" style={iconStyle}></i> home</Link></li>
            <li><Link to="/about"> <i className="fa fa-church" style={iconStyle}></i> about</Link></li>
            <li><Link to="/store"> <i className="fa fa-shop" style={iconStyle}></i> store</Link></li>
            <li><Link to="/contact"> <i className="fa fa-phone" style={iconStyle}></i> contactUs</Link></li>
          </ul>
        </div>


        <div className="footer-menu-item">
          <h3>happening</h3>
          <ul>
            <li><Link to="/live"> <i className="fa fa-rss" style={iconStyle}></i> live</Link></li>
            <li><Link to="/events"> <i className="fa fa-calendar-days" style={iconStyle}></i> events</Link></li>
            <li><Link to="/posts"> <i className="fa fa-signs-post" style={iconStyle}></i> posts</Link></li>
            <li><Link to="/news"> <i className="fa fa-newspaper" style={iconStyle}></i> news</Link></li>
          </ul>
        </div>

        <div className="footer-menu-item">
          <h3>posts</h3>
          <ul>
            {merchant.socials.map(function(item){
              return <li key={item.id}><Link to={item.link}> <i className={item.icon} style={iconStyle}></i> {item.title}</Link></li>
            })}
          </ul>
        </div>
        
        <div className="footer-menu-item">
          <h3>Ministry work</h3>
          <ul>
            <li><Link to=""> <i className="fa fa-video" style={iconStyle}></i> zoom meetings</Link></li>
            <li><Link to=""> <i className="fa fa-book" style={iconStyle}></i> Ebooks</Link></li>
            <li><Link to=""> <i className="fa fa-bible" style={iconStyle}></i> Sermons</Link></li>
            <li><Link to=""> <i className="fa fa-music" style={iconStyle}></i> ministry music</Link></li>
          </ul>
        </div>

      </div>{/* end of footer menu */} 

      <div className="footer-addressing">
          <span>
            <h5>address</h5>
            <small>{merchant.address}</small>
          </span>

          <span>
            <h5>international calls</h5>
            <small>{merchant.phone.international}</small>
          </span>

          <span>
            <h5>uganda calls</h5>
            <small>{merchant.phone.tel}</small>
          </span>

          <span>
            <h5>email address</h5>
            <small>{merchant.email}</small>
          </span>

      </div>
    </section>

    <section className="footer-mapping">
      <iframe src={merchant.mapLocation} width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </section>

    <nav>
      <span className="footer-nav-left">
        <span className="footer-nav-left-church-title">{merchant.merchant_title}</span> @ 2023 all rights reversed</span>
      <span className="footer-nav-right">
        <span className="footer-nav-right-link">
          <Link href="/terms-of-service">terms of service</Link> . <Link to="/">golcitytv</Link>
        </span>
        <span className="footer-nav-right-socials">
          {merchant.socials.map(function(item){
            return <Link key={item.id} to={item.link}><i style={{ fontSize: "13px", color: "var(--socialsColor)", marginLeft: "10px" }} className={item.icon}></i></Link>
          })}
          
          </span>
        </span>
    </nav>
  </footer>
</>
  )
}

/* 
<iframe src="" width="600" height="450" style="border:0;" ></iframe> */