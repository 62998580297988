import React from "react";
import EmailSend from "../components/EmailSend";
import { useDispatch, useSelector } from "react-redux";
import { constantActions } from "../store/constantSlice";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default function Contact() {
  const dispatch = useDispatch();
  const companyName = useSelector((state) => state.constant.companyTitle);

  const merchant = useSelector((state) => state.constant.data.merchantDetails);

    dispatch(
    constantActions.updatePageTitles(companyName + " . " + "Contact Us")
  );
  dispatch(constantActions.setCurrentPage("Contact"));

  return (
    <>
      <Helmet>
        <title>{companyName} . Contact Us</title>
        <link rel="canonical" href="/contact"/>
        <meta name="author" content="www.jambumoses.com" />

        <meta
          name="keywords"
          content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
        />

        <meta
          name="description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        {/* opengraph */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={companyName + " | " + "Contact Us"} />
        <meta
          property="og:description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        <meta property="og:url" content="https://golcitytv.com/contact" />
        <meta property="og:site_name" content="golcitytv" />
        <meta
          property="article:modified_time"
          content="2023-03-02T10:27:44+00:00"
        />
        <meta
          property="og:image"
          content={require("../assets/imgs/skins/edwin-andrade-6liebVeAfrY-unsplash.jpg")}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section>
        <div
          className="homeBanner"
          style={{
            height: "400px",
            background: `url(${require("../assets/imgs/skins/edwin-andrade-6liebVeAfrY-unsplash.jpg")}) center no-repeat`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Link to="/donate" className="homeBanner-donate-btn">
            <button>
              <i className="fa fa-heart"></i> donate
            </button>
          </Link>
          <span className="homeBannerHeading">
            <h1>LET'S FELLOWSHIP TOGETHER</h1>
            <p style={{ color: "gray" }}>
              “I want the whole Christ for my Saviour, the whole Bible for my
              book, the whole Church for my fellowship, and the whole world for
              my mission field.”
              <br />~ John Wesley
            </p>
          </span>
        </div>
        {/* <main className="home-main"></main> */}
      </section>

      <br />

      {/* Send Email */}
      <EmailSend />

      <section className="contact-small-about-section">
        <div className="about-small">
          <h1>about us</h1>
          <p>
            In simple words we are a community which is all about Lord Jesus
            Christ based in the beautiful twin cities Hyderabad & Secunderabad.
            We are committed to proclaim and practice the life of Jesus Christ
            individually and even as community. At JSCityChurch we are devoted
            to Reformed Theology and Expository preaching and teaching, it is
            also a great place for those who are looking for English Church and
            also has separate Telugu worship.
          </p>
        </div>

        <div className="about-address">
          <h1>address</h1>
          <p>{merchant.address}</p>
        </div>
      </section>
    </>
  );
}
