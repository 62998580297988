import React from "react";

export default function LiveSreen() {
  return (
    <>
      <section className="livescreen-section">
        <iframe
          src="https://player.castr.com/live_814450b07f6011eeacd88b40b369a01e"
          
          style={{aspectRatio: "16/9", minHeight: "340px"}}
          frameborder="0"
          scrolling="no"
          allow="autoplay"
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen
          oallowfullscreen
          msallowfullscreen
        ></iframe>
      </section>
    </>
  );
}
