import React from "react";

export default function EmptySermon() {
  return (
    <div className="empty-sermon-section">
      <h1>no sermons</h1>
      <p>
        their no sermons uploaded <br />
        yet
      </p>
    </div>
  );
}
