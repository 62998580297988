import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constantActions } from "../store/constantSlice";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

export default function About() {
  const dispatch = useDispatch();
  const companyName = useSelector((state) => state.constant.companyTitle);

  dispatch(constantActions.updatePageTitles(companyName + " . " + "About"));
  dispatch(constantActions.setCurrentPage("About"));

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <Helmet>
        <title>{companyName} . About</title>
        <link rel="canonical" href="/about" />
        <meta name="author" content="www.jambumoses.com" />

        <meta
          name="keywords"
          content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
        />

        <meta
          name="description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />

        {/* opengraph */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={companyName + " | " + "About"} />
        <meta
          property="og:description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        <meta property="og:url" content="https://golcitytv.com/about" />
        <meta property="og:site_name" content="golcitytv" />
        <meta
          property="article:modified_time"
          content="2023-03-02T10:27:44+00:00"
        />
        <meta
          property="og:image"
          content={require("../assets/imgs/posters/nolan-kent-ft4VQt01cCw-unsplash.jpg")}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section>
        <div
          className="homeBanner"
          style={{
            height: "400px",
            background: `url(${require("../assets/imgs/posters/nolan-kent-ft4VQt01cCw-unsplash.jpg")}) center no-repeat`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Link to="/donate" className="homeBanner-donate-btn">
            <button>
              <i className="fa fa-heart"></i> donate
            </button>
          </Link>
          <span className="homeBannerHeading about-banner-titles">
            <h1>about us</h1>
            <p
              style={{
                color: "white",
                wordSpacing: "5px",
                letterSpacing: "2px",
              }}
            >
              “Wherever we see the Word of God purely preached and heard, there
              a church of God exists, even if it swarms with many faults.”
              <br />- John Calvin
            </p>

            <p
              style={{
                color: "white",
                wordSpacing: "5px",
                letterSpacing: "2px",
              }}
            >
              The Church is the one institution that exists for those outside
              it.
              <br />- William Tyndale
            </p>
          </span>
        </div>
        <main className="home-main">
          <section className="about-content-section">
            <div className="vision">
              <h1>our vision</h1>
              <p data-aos="fade-up">
                To be the community where Lord Jesus is explored and lived out
                individually and collectively in the present generation and
                generations to come in the city of Hyderabad and other cities.
              </p>
              <p data-aos="fade-up">
                "...and you will be my witnesses in Jerusalem and in all Judea
                and Samaria, and to the end of the earth...” Acts 1:8
              </p>
            </div>

            <span className="vision-pastor-thumbnail">
              <LazyLoadImage
                src={require("../assets/imgs/posters/dfdf.png")}
                alt=""
              />
            </span>

            <div className="whatwe-do">
              <h1 data-aos="fade-up">what we do</h1>
              <div data-aos="fade-left" className="whatwe-do-note">
                <p>
                  We are devoted to answer the questions people have at every
                  stage of their lives from the life of Lord Jesus. For we
                  strongly believe that Jesus is the way, the truth and the life
                  for fruitful life here up on the earth and celebrated life
                  after the death.
                </p>
                <p>
                  "Jesus said to him, “I am the way, and the truth, and the
                  life. No one comes to the Father except through me." John
                  14:6"
                </p>
              </div>
            </div>
          </section>

          <section className="about-community-section">
            <h1>OUR COMMUNITY</h1>
            <p data-aos="fade-up">
              We are a bunch of passionate people with the burden to serve God
              like his Son Lord Jesus served, denying his own will and doing the
              will of the Father not just once but all his earthly life which
              includes the death on the cross.
            </p>

            <p data-aos="fade-up">
              Lord Jesus came to this world two thousand years ago to save
              sinners from their sins, and therefore we partner with him today
              in the same business of saving his people from their sins.
            </p>

            <p data-aos="fade-up">
              We are a young bunch of people, launching the church and we
              definitely need more people to join our team who have no other
              passion but the passion of Jesus Christ.
            </p>
          </section>

          <section className="about-faith-section">
            <h1 data-aos="fade-right">the faith</h1>
            <p data-aos="fade-right">
              The Church is the one institution that exists for those outside
              it.
              <br />- William Tyndale
            </p>
          </section>

          <section className="about-Church-section">
            <h2>Church-The Body Of Christ Jesus</h2>
            <hr />
            <p>
              The truth concerning the Church can be understood under three main
              subjects, namely the Transcendent Church, the Universal or the
              World Church and the Local Church.
            </p>
            <section className="about-Church-subjects-section">
              <div>
                <h4 data-aos="fade-up">Transcendent Church: </h4>
                <p data-aos="fade-up">
                  This is the invisible and spiritual Church being built by the
                  Lord Jesus Christ, this is not bound by time nor by space;
                  this is the Body wherein God has filled it with all His
                  fullness in absolute sense; every stone is living stone no
                  dead stone is found; this is the standard or model church
                  based on which the Universal Church and the Local Church have
                  to model themselves.
                </p>
              </div>

              <div>
                <h4 data-aos="fade-up">Universal church or World Church: </h4>
                <p data-aos="fade-up">
                  This is a time bound church, which is present now and spreads
                  across the Globe. It is one church as well as many, one
                  because it is built on the same foundational truth the Gospel,
                  but many because of peripheral doctrinal differences and also
                  many because it is geographical separated.
                </p>
              </div>

              <div>
                <h4 data-aos="fade-up">Local Church:</h4>
                <p data-aos="fade-up">
                  The local church is a time bound and geographically bound
                  community available for the children of God to exercise the
                  biblical truths and to exercise the love of God for one
                  another in a practical way. In the Bible we clearly notice
                  that these communities were available at a different
                  geographical locations, for example Church at Jerusalem,
                  Church at Antioch, Church at Ephesus, Church at Corinth, etc.
                  for the purpose of living out the blessed Lord Jesus Christ.
                  As Jesus Christ was reflecting the personality and nature of
                  God the local church is called to imitate Lord Jesus Christ in
                  reflecting God.
                </p>
              </div>

              <div>
                <h4 data-aos="fade-up">Baptism Of The Holy Spirit</h4>
                <p data-aos="fade-up">
                  Gospel assures of complete and dramatic change of the heart
                  and the same Gospel also assures a born again individual will
                  experience the growth in the person of Lord Jesus Christ.
                  Gospel also assures the fruits of Love, Faith and Hope.
                </p>
              </div>
            </section>

            <p data-aos="fade-up" className="about-Church-subjects-quote">
              If this is the Gospel outcome of one's life and if above mentioned
              glorious signs of transformation are evident in one's life then he
              holds without any doubt a right doctrine, It doesn't matter what
              stand he takes on this subject.
            </p>

            <span className="if-section-thumbnail">
              <LazyLoadImage
                src={require("../assets/imgs/church/pastor2.png")}
                alt=""
              />
            </span>
          </section>

          <section className="about-God-prayer-section">
            <div data-aos="fade-up">
              <h1>God-The Triune</h1>
              <p>
                God Is One; but revealed Himself as three distinct blessed
                persons, God the Father, God the Son and God the Holy Spirit in
                the Holy Scriptures. This doctrine is based not just on
                trinitarian passages but based primarily on how Scriptures
                speaks of the Father, the Son and the Holy Spirit.
              </p>
            </div>
            <div data-aos="fade-up">
              <h1>God The Father:</h1>
              <p>
                God in His good pleasure and grace, revealed Himself to man who
                is the chief of all His creation upon the earth; God is the one
                who is distinct; the Creator; coexisting in fellowship of God
                the Son eternally; He is the one Holy and dwells in
                unapproachable glory, He is the Supreme, He is the law giver, He
                is the one who holds times in His authority.
              </p>
            </div>

            <div data-aos="fade-up">
              <h1>God The Son:</h1>
              <p>
                God in His good pleasure and grace revealed His Son in inspired
                scriptures; One who is distinct, the creator, He is coexisting
                with God the Father eternally; He is the one sharing glory of
                God; He Is the God incarnated in the image of man; He is the
                Truth and the express image of God; He is the Servant upon whose
                work the salvation of mankind is designed.{" "}
              </p>
            </div>

            <div data-aos="fade-up">
              <h1>God The Holy Spirit:</h1>
              <p>
                God in His good pleasure and grace revealed the invisible but
                the perceptible Holy Spirit; One who is distinct; the author of
                the inspired scriptures; He is the One who operated the
                incarnation of God The Son in the image of man; He is the
                inspiration and persuasion in the hearts of men unto salvation;
                He is the One through whom God the Father and God the Son
                manifest themselves in the hearts of His children.{" "}
              </p>
            </div>
          </section>
        </main>
      </section>

      <br />
    </>
  );
}
