
import $ from 'jquery';
import React, { useEffect } from "react";
import CarouselComponent from "../components/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { constantActions } from "../store/constantSlice";
import OutNow from "../components/OutNow";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
/* 
import "../assets/textScrolls/gsap.min.js";
import "../assets/textScrolls/split-type.js";
import "../assets/textScrolls/ScrollTrigger.min.js";
import "../assets/textScrolls/action.js";
import "../assets/textScrolls/text-stagger.css"; */


export default function Home() {
  const dispatch = useDispatch();
  const companyName = useSelector((state) => state.constant.companyTitle);

  dispatch(constantActions.updatePageTitles(companyName));
  dispatch(constantActions.setCurrentPage("Home"));

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <Helmet>
        <title>{companyName}</title>
        <link rel="canonical" href="/home" />
        <meta name="author" content="www.jambumoses.com" />

        <meta
          name="keywords"
          content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
        />

        <meta
          name="description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        {/* opengraph */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={companyName}
        />
        <meta
          property="og:description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        <meta property="og:url" content="https://golcitytv.com/" />
        <meta property="og:site_name" content="golcitytv" />
        <meta
          property="article:modified_time"
          content="2023-03-02T10:27:44+00:00"
        />
        <meta
          property="og:image"
          content={require("../assets/imgs/posters/frf.jpg")}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section>
        <CarouselComponent />

        <main className="home-main">
          <section className="home-main-context">
            {/* context */}
            <div className="welcome-note">
              <span data-aos="fade-up" className="welcome-note-thumbnail">
                <h4 letters-fade-in-random="" text-split="">
                  worship
                </h4>
                <small letters-fade-in-random="" text-split="">
                  with us
                </small>
                {/* <LazyLoadImage src="" alt=""> */}
              </span>
              <span className="welcome-note-info">
                <h3 data-aos="fade-left">
                  welcome to <span>Glory Of The Lord's City</span> Ministries
                  International
                </h3>
                <p data-aos="fade-up">
                  We are devoted to answer the questions people have at every
                  stage of their lives from the life of Lord Jesus. For we
                  strongly believe that Jesus is the way, the truth and the life
                  for fruitful life here up on the earth and celebrated life
                  after the death.
                </p>
              </span>
            </div>

            <div className="home-wrapper">
              <h1
                words-slide-up=""
                text-split=""
                className="main-hero_sub-heading"
              >
                Glory Of The Lord's City Ministries
              </h1>
              <p data-aos="fade-up">
                At the end of verse 12 in Ephesians 4, we learn that the goal
                and purpose of ministry is to build up the body of Christ: "…to
                equip the saints for the work of ministry, for building up the
                body of Christ…” (Eph 4:12)
              </p>
              <Link to="/about" data-aos="fade-up">
                <button type="button">read more</button>
              </Link>
            </div>
            {/* 

                        <div>
                          <div>events</div>
                          <div>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel itaque vitae distinctio enim quo, quod ea porro quasi neque omnis totam dicta quae, labore voluptas deserunt ex facere iure quam!</p>
                          </div>
                        </div>

                        <div className="music-selection-section">
                          <small>the music</small>
                          <div>the quier</div>
                          <div>
                            <p>music Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel itaque vitae distinctio enim quo, quod ea porro quasi neque omnis totam dicta quae, labore voluptas deserunt ex facere iure quam!</p>
                          </div>
                        </div>  */}

            <div className="social-connection-section">
              <div className="social-connection">
                <div
                  words-slide-up=""
                  text-split=""
                  className="social-connection-title"
                >
                  social connection
                </div>
                <section className="social-connection-container">
                  <div data-aos="fade-up" className="social-connection-item">
                    <span>
                      {/* <LazyLoadImage src="" alt="">  */}{" "}
                      <i className="fa fa-bible"></i>{" "}
                    </span>
                    <p>
                      With content that helps you understand biblical customs
                      through the lens of similar African traditions and
                      reflects on Africa's cultures and daily life, each
                      devotional will help you grow deeper in your relationship
                      with Christ.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="social-connection-item">
                    <span>
                      {/* <LazyLoadImage src="" alt=""> */}{" "}
                      <i className="fa fa-church"></i>
                    </span>
                    <p style={{ height: "auto" }}>
                      The church is not a select circle of the immaculate, but a
                      home where the outcast may come in. It is not a palace
                      with gate attendants and challenging sentinels along the
                      entrance-ways holding off at arm's-length the stranger,
                      but rather a hospital where the broken-hearted may be
                      healed, and where all the weary and troubled may find rest
                      and take counsel together.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="social-connection-item">
                    <span>
                      {/* <LazyLoadImage src="" alt=""> */}{" "}
                      <i className="fa fa-person"></i>
                    </span>
                    <p>
                      The God who created the universe, the most powerful being
                      ever, loves you personally. Try to wrap your mind around
                      that. He loves you — not just people in general, but you
                      as a person. He cares about your life and wants to be part
                      of it.
                    </p>
                  </div>
                </section>
              </div>

              {/* circle */}
              <div className="social-circle-section">
                <span className="social-circle-container">
                  <span className="social-circle-bar"></span>
                </span>
              </div>
            </div>

            <OutNow />

            <div className="join-ministry-section">
              <span className="join-ministry-poster">
                <LazyLoadImage
                  src={require("../assets/imgs/skins/sincerely-media-dGxOgeXAXm8-unsplash.jpg")}
                  alt=""
                />
              </span>
              <div>
                <h3 data-aos="fade-up">join our ministry</h3>
                <p data-aos="fade-up">
                  Come Grow, Meet Real People, Engage in Relevant Teaching, &
                  Serve Our Community. Learn the Bible, Grow Your Faith. Real
                  Answers to Life. True to God's Word. Thriving Students.
                  Serving the Community. Serving Community. Kids Love It. Active
                  Adults.
                </p>
                <a href="">
                  <button data-aos="fade-up" type="button">
                    join today
                  </button>
                </a>
              </div>
            </div>

            {/* <div>
                          <div>pastors</div>
                          <div>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel itaque vitae distinctio enim quo, quod ea porro quasi neque omnis totam dicta quae, labore voluptas deserunt ex facere iure quam!</p>
                          </div>
                        </div>  */}

            <div className="communitee-section">
              <div className="communitee-note">
                <div className="communitee-banner-section">
                  <span data-aos="fade-up" className="communitee-banner-bar">
                    <span></span>
                  </span>

                  {/* <div className="communitee-banner-bar-nav">nav</div> */}
                </div>
                <p data-aos="fade-up">
                  As the church, we are in community together trying to fulfill
                  this Great Commission that Jesus left us with. As we gently
                  press into each other, we form one united thing, His church.
                  As we work together, sharing the space God gives us to do His
                  work, we all become shaped a little different. We all become a
                  little more like Him.
                </p>
                <h1
                  data-aos="fade-up"
                  words-slide-up=""
                  text-split=""
                  className="communitee-title"
                >
                  our community
                </h1>
                <h1
                  data-aos="fade-up"
                  words-slide-up=""
                  text-split=""
                  className="communitee-author"
                >
                  pastor elijah
                </h1>

                <div data-aos="fade-up" className="communitee-links">
                  <span>
                    <i className="fa fa-arrow-right"></i>
                  </span>
                  <a href="">
                    <button type="button">the road to salvation</button>
                  </a>
                </div>
              </div>
              <span className="communitee-poster">
                <LazyLoadImage
                  src={require("../assets/imgs/posters/dfdf.png")}
                  alt=""
                />
              </span>
            </div>
          </section>

          <aside className="home-main-aside">
            {/*  product categories
                        socials posts
                        social links
                        payment methods
                        donations
                         calender  */}
          </aside>
        </main>
      </section>
    </>
  );
}
