/* modules */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
/* common */
import Footer from "./common/Footer";
import Header from "./common/Header";
/* components */
import SocialPostScroll from "./components/SocialPostScroll.jsx";
import EmailSubscription from "./components/EmailSubscription.jsx";
/* messages */
import FailedPayment from "./messages/FailedPayment";
import SuccessfulPayment from "./messages/SuccessfulPayment";

/* pages */
import Home from "./pages/Home";
import About from "./pages/About";
import Sermons from "./pages/Sermons";
import Gallery from "./pages/Gallery";
import Live from "./pages/Live";
import Contact from "./pages/Contact";
import Donate from "./pages/Donate";
import Cart from "./pages/Cart";
import Details from "./pages/Details";
import { constantActions } from "./store/constantSlice";
import Checkout from "./pages/Checkout";
import Payment from "./pages/Payment";
import axios from "axios";

function App() {
  const dispatch = useDispatch();

  const browserIcons = useSelector((state) => state.constant.logo.browserTab);
  const currentPage = useSelector((state) => state.constant.currentPage);
  const PageTitles = useSelector((state) => state.constant.currentPageTitle);

  const apiOrigin = useSelector((state) => state.constant.apiOrigin);

  useEffect(() => {
    /* Browser icons */
    $("#browser-icons").attr("href", browserIcons);

    // updating page titles
    $("#app-title").text(PageTitles);

    /* update cart count number */
    dispatch(constantActions.updateCartCount());

    /* update cart subtotal */
    dispatch(constantActions.cartSubtotal());

    /* update cart total */
    dispatch(constantActions.cartTotal());
  });

  // fetch-URL
  const sermonFetch = axios.create({
    baseURL: apiOrigin + "/api/sermons",
    Headers: {
      "Content-type": "application/json",
    },
  });

  const merchantFetch = axios.create({
    baseURL: apiOrigin + "/api/merchant",
    Headers: {
      "Content-type": "application/json",
    },
  });

  const outNowFetch = axios.create({
    baseURL: apiOrigin + "/api/outnow",
    Headers: {
      "Content-type": "application/json",
    },
  });

  const socialsFetch = axios.create({
    baseURL: apiOrigin + "/api/socials",
    Headers: {
      "Content-type": "application/json",
    },
  });

  const galleryFetch = axios.create({
    baseURL: apiOrigin + "/api/gallery",
    Headers: {
      "Content-type": "application/json",
    },
  });
  // end of fetch-URL

  useEffect(() => {
    // sermons-DATA
    sermonFetch
      .get("")
      .then((response) => {
        dispatch(
          constantActions.UpdateGolcitytv({
            type: "sermons",
            data: response.data,
          })
        );
      })
      .catch((error) => {
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });

    // merchant-DATA
    merchantFetch
      .get("")
      .then((response) => {
        dispatch(
          constantActions.UpdateGolcitytv({
            type: "merchant",
            data: response.data,
          })
        );
      })
      .catch((error) => {
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });

    // outnow-DATA
    outNowFetch
      .get("")
      .then((response) => {
        dispatch(
          constantActions.UpdateGolcitytv({
            type: "outnow",
            data: response.data,
          })
        );
      })
      .catch((error) => {
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });

    // socials-DATA
    socialsFetch
      .get("")
      .then((response) => {
        dispatch(
          constantActions.UpdateGolcitytv({
            type: "socials",
            data: response.data,
          })
        );
      })
      .catch((error) => {
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });

    // gallery-DATA
    galleryFetch
      .get("")
      .then((response) => {
        dispatch(
          constantActions.UpdateGolcitytv({
            type: "gallery",
            data: response.data,
          })
        );
      })
      .catch((error) => {
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
    /* end of requests */
  });

  return (
    <>
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/build" element={<Details />} />

          {/* public routes */}
          <Route path="/" element={<Home />} />
          <Route path="/index" element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route path="/about" element={<About />} />
          <Route path="/live" element={<Live />} />
          <Route path="/sermons" element={<Sermons />} />
          <Route path="/store" element={<Sermons />} />

          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/cart" element={<Cart />} />
          {/* details */}
          <Route path="/sermons/details/:id" element={<Details />} />
          <Route path="/sermons/:id" element={<Sermons />} />
          {/* checkout */}
          <Route path="/checkout" element={<Checkout />} />
          {/* messages */}
          <Route path="/cancel_payments" element={<FailedPayment />} />
          <Route path="/successful_payments" element={<SuccessfulPayment />} />

          {/* payment */}
          <Route path="/make-payments" element={<Payment />} />

          {/* pending */}
          <Route path="/events" element={<Home />} />
          <Route path="/posts" element={<Home />} />
          <Route path="/news" element={<Home />} />

          {/* 404 */}
          <Route path="*" element={<Home />} />
        </Routes>

        {currentPage === "Home" && <SocialPostScroll />}

        {currentPage !== "Details" &&
          currentPage !== "Cart" &&
          currentPage !== "About" && <EmailSubscription />}

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
