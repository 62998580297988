import React, { useRef } from "react";
import { useSelector } from "react-redux";
import emailjs from "@emailjs/browser";
import {LazyLoadImage} from "react-lazy-load-image-component";

export default function EmailSend() {
  const merchant = useSelector((state) => state.constant.data.merchantDetails);

  const form = useRef();

  function sendMessage(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_k6wxp8d",
        "template_4z7xanf",
        form.current,
        "cnp9o_WkKuuvpPHv7"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

      e.target.reset()
  }

  return (
    <>
      <section className="email-subscription">
        <span>
          <h1>send a message</h1>
          <p>
            receive updates on whats happening at golcitytv on your email, or
            mobile phone.
          </p>
          <form action="" ref={form} onSubmit={sendMessage}>
            <span>
              <input
                type="text"
                name="firstname"
                id=""
                placeholder="FirstName *"
                required
              />
              <input
                type="text"
                name="lastname"
                id=""
                placeholder="LastName *"
                required
              />
            </span>
            <input
              type="email"
              name="email"
              id=""
              placeholder="Email Address *"
              required
            />
            <input
              type="text"
              name="subject"
              id=""
              placeholder="Subject *"
              required
            />
            <textarea
              name="message"
              cols="30"
              rows="10"
              required
              placeholder="Enter Your Message Here"
            ></textarea>
            <button type="submit">submit</button>
          </form>
        </span>

        <span className="email-subscription-image">
          {/* map */}
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: 0,
              borderRadius: "10px",
            }}
            /* width="600" height="450" */ src={merchant.mapLocation}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* <LazyLoadImage src="" alt=""> */}
        </span>
      </section>
    </>
  );
}
