import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { constantActions } from "../store/constantSlice";
import { Helmet } from "react-helmet-async";
import $ from "jquery";
import { useNavigate } from "react-router-dom";

export default function Donate() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const companyName = useSelector((state) => state.constant.companyTitle);
  const apiOrigin = useSelector((state)=> state.constant.apiOrigin)

  dispatch(constantActions.updatePageTitles(companyName + " . " + "Donate"));
  dispatch(constantActions.setCurrentPage("Donate"));

  /* inputs */
  const donate_inputs = useSelector((state)=>state.constant.data.donation);

  async function payDonation(){
    await fetch(`${apiOrigin}/create-checkout-session/`,{
      method:"POST",
      headers:{
        'content-type':'application/json',
      },
      body: JSON.stringify(donate_inputs)
    }).then((response)=>{
        return response.json()
    }).then((response)=>{
      console.log(response)
      if (response.url) {
        window.location.assign(response.url) 
      }
    }).catch(error=>{
      console.log(error)
    });
    //navigate("/make-payments?type=donation");


    $('html').css("overflow","auto")
    $('.donation-wrapper').css("display","none")
  }

  return (
    <>
      <Helmet>
        <title>{companyName} . Donate</title>
        <link rel="canonical" href="/donate" />
        <meta name="author" content="www.jambumoses.com" />

        <meta
          name="keywords"
          content="golcity,golcitytv,golcity tv,gol, city,gol city,gol church,church, city church,golcity church,golcitytv church,gol city church,golcity switzerland, golcity Montetan,golcity Lausanne,golcity live"
        />

        <meta
          name="description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        {/* opengraph */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={companyName + " | " + "Donate"} />
        <meta
          property="og:description"
          content="At Golcitytv We are devoted to answer the questions people have at every stage of their lives from the life of Lord Jesus. For we strongly believe that Jesus is the way, ..."
        />
        <meta property="og:url" content="https://golcitytv.com/donate" />
        <meta property="og:site_name" content="golcitytv" />
        <meta
          property="article:modified_time"
          content="2023-03-02T10:27:44+00:00"
        />
        <meta property="og:image" content="" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section
        className="donation-section"
        style={{ padding: "3% 5%", paddingTop: "130px" }}
      >
        {/* donation details */}
        <div className="donation-wrapper">
          <span className="wrap-close" onClick={()=>{$('html').css("overflow","auto");$('.donation-wrapper').css("display","none")}}>
            <i className="fa fa-remove"></i>
          </span>

          <div className="wrap-section">
            <section className="wrapper-persion-info-section">
              <h2>personal information</h2>
              <form>
                <span>
                  <input type="text" name="FirstName" placeholder="FirstName" onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"personal-info-firstname",data : e.target.value}))} value={donate_inputs.personalInfo.firstName} />
                </span>
                <span>
                  <input type="text" name="LastName" placeholder="LastName" onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"personal-info-lastname",data : e.target.value}))} value={donate_inputs.personalInfo.lastName} />
                </span>
                <span>
                  <input type="email" name="email" placeholder="Email" onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"personal-info-email",data : e.target.value}))} value={donate_inputs.personalInfo.email} />
                </span>
                <span>
                  <input
                    type="text"
                    name="phonenumber"
                    placeholder="Phone Number"
                    onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"personal-info-phoneNumber",data : e.target.value}))} value={donate_inputs.personalInfo.phoneNumber}
                  />
                </span>
              </form>
            </section>

            <section className="wrapper-billing-section">
              <h2>billing information</h2>
              <form>
                <span>
                  <input type="text" name="address" placeholder="Address" onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"billing-info-address",data : e.target.value}))} value={donate_inputs.billingInfo.address}/>
                </span>
                <span>
                  <input
                    type="text"
                    name="addressline"
                    placeholder="Address Line"
                    onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"billing-info-address-line",data : e.target.value}))} value={donate_inputs.billingInfo.addressLine}
                  />
                </span>
                <span>
                  <input type="text" name="city" placeholder="City" onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"billing-info-city",data : e.target.value}))} value={donate_inputs.billingInfo.city}/>
                </span>
                <span>
                  <input type="text" name="state" placeholder="State" onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"billing-info-state",data : e.target.value}))} value={donate_inputs.billingInfo.state}/>
                </span>
                <span>
                  <input
                    type="text"
                    name="postalcode"
                    placeholder="Postal Code"
                    onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"billing-info-postalCode",data : e.target.value}))} value={donate_inputs.billingInfo.postalCode}
                  />
                </span>
                <span>
                  <input type="text" name="country" placeholder="Country" onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"billing-info-country",data : e.target.value}))} value={donate_inputs.billingInfo.country}/>
                </span>
              </form>
            </section>
          </div>

          <div className="wrap-button-section">
            <button type="button" onClick={payDonation}>Donate Now</button>
          </div>
        </div>
        {/* end of donation details */}






        <section className="donation-container">
          <h2>thank you for helping us</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore
            accusantium voluptatum illo mollitia non est minus autem vitae cum
            at et dolores sint, ea eius voluptatem error, quidem soluta
            similique.
          </p>
          <div className="donation-item">
            <span className="donation-item-header">
              Enter an amount to give
            </span>
            <form onSubmit={() => null}>
              <span className="donation-item-input">
                <input type="text" name="price" placeholder="$ 80" onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"price",data : e.target.value}))} value={donate_inputs.price} disabled={(donate_inputs.type === "monthly")?true:false}/>
                <span style={{textTransform:"uppercase"}}>{donate_inputs.currency}</span>
              </span>

              <span className="donation-item-checkbox">
                <input type="checkbox" name="insomeoneshonor" onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"honors",data : e.target.value}))} checked={donate_inputs.honors} />
                <small>give in honor of someone</small>
              </span>

              <div className="donation-item-honor-details-section" style={{display: (donate_inputs.honors)? "flex":"none"}}>
                <span>
                  <input
                    type="text"
                    name="honorFirstName"
                    placeholder="Honor's FirstName"
                    onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"honor-fname",data : e.target.value}))} value={donate_inputs.someonesHonors.firstName}
                  />
                </span>
                <span>
                  <input
                    type="text"
                    name="honorLastName"
                    placeholder="Honor's LastName"
                    onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"honor-lname",data : e.target.value}))} value={donate_inputs.someonesHonors.lastName}
                  />
                </span>
                <span>
                  <input
                    type="email"
                    name="honorEmail"
                    placeholder="Honor's Email"
                    onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"honor-email",data : e.target.value}))} value={donate_inputs.someonesHonors.email}
                  />
                </span>
                <span>
                  <input
                    type="text"
                    name="honorNumber"
                    placeholder="Honor's Number"
                    onChange={(e)=> dispatch(constantActions.donateUpdate({tag :"honor-phoneNumber",data : e.target.value}))} value={donate_inputs.someonesHonors.phoneNumber}
                  />
                </span>
              </div>

              <button type="button" onClick={()=>{$('html').css("overflow","hidden");$('.donation-wrapper').css("display","flex")}}>donate now</button>
            </form>
          </div>
        </section>

        <section className="auto-donation-section">
          <span className="auto-donation-sure">
            <i style={{ color: "var(--success)" }} className="fa fa-lock"></i>{" "}
            sure donation
          </span>
          <div className="auto-donation-price-tag">
            <span className="title">
              <i
                style={{ color: "red", paddingRight: "10px" }}
                className="fa fa-heart"
              ></i>{" "}
              monthly
            </span>
            <span>
              <input type="radio" name="monthly-donate" id="" onChange={(e)=>{dispatch(constantActions.donateUpdate({tag :"type",data : "monthly"}));dispatch(constantActions.donateUpdate({tag :"price",data : e.target.value}))}} value={5} /> $ 5
            </span>
            <span>
              <input type="radio" name="monthly-donate" id="" onChange={(e)=>{dispatch(constantActions.donateUpdate({tag :"type",data : "monthly"}));dispatch(constantActions.donateUpdate({tag :"price",data : e.target.value}))}} value={10} /> $ 10
            </span>
            <span>
              <input type="radio" name="monthly-donate" id="" onChange={(e)=>{dispatch(constantActions.donateUpdate({tag :"type",data : "monthly"}));dispatch(constantActions.donateUpdate({tag :"price",data : e.target.value}))}} value={20} /> $ 20
            </span>
          </div>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. A
            consequuntur ratione dolore est dolorum! Quisquam itaque molestias
            rem tempora, ipsum officia, incidunt cupiditate assumenda sit
            debitis eius fugit esse pariatur?
          </p>
        </section>
      </section>
    </>
  );
}
