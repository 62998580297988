import React from "react";

export default function EmptyGallery() {
  return (
    <>
      <section className="empty-gallery">
        <p>empty gallery</p>
        <small style={{ textTransform: "capitalize" }}>
          their no image uploaded in the gallery
          <br />
          yet
        </small>
      </section>
    </>
  );
}
